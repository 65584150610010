import React from 'react';
import './ContactUs.css';
import emailicons from '../../Images/contactmail.svg'
import phoneicons from '../../Images/contactphone.svg'
import locationicons from '../../Images/contactmap.svg'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';


function ContactUs() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_3natzca', 'template_damxxu6', form.current, '8E3N8-nPm89OjwCez')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);

      });
    e.target.reset()
  };
  return (
    <>

      <section class="contact-area contact-page-area">
        <div class="container">
          <div class="row contact-page-wrapper">
            <div className='bannersection'>
              <h2>Get In Touch</h2>
            </div>
            <div class="col-xl-9">
              <div class="contact-form-wrap aos-init aos-animate" data-aos="fade-right">
                <div class="contact-form">
                  <form id="contact-form" ref={form} onSubmit={sendEmail}>
                    <div className="row row-gutter-20">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input className="form-control" type="text" name="user_name" placeholder="Name *" required />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input className="form-control" type="email" name="user_email" placeholder="Email *" required />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input className="form-control" name="user_subject" type="text" placeholder="Subject*" required />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb--0">
                          <textarea className="form-control" name="message" placeholder="Message*" required></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb--0 last">
                          <button className="btn-contact" type="submit" value="Send">Send Message</button>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="contact-info-wrap">
                <div class="contact-info">
                  <div class="row">
                    <div class="col-lg-4 col-xl-12">
                      <div class="info-item aos-init aos-animate" data-aos="fade-left" data-aos-delay="60">
                        <div class="icon">
                          <img src={phoneicons} width="40" height="40" alt="Image-HasTech" />
                        </div>
                        <div class="info">
                          <p>
                            <a href="tel:+919825802008">+91 - 98258 02008</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-xl-12">
                      <div class="info-item aos-init aos-animate" data-aos="fade-left" data-aos-delay="120">
                        <div class="icon">
                          <img src={emailicons} width="40" height="40" alt="Image-HasTech" />
                        </div>
                        <div class="info">
                          <p>
                            <a href="mailto:info@thelaminc.com">info@thelaminc.com</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-xl-12">
                      <div class="info-item aos-init aos-animate" data-aos="fade-left">
                        <div class="icon">
                          <img src={locationicons} width="40" height="40" alt="Image-HasTech" />
                        </div>
                        <div class="info">
                          <p>1, Sayona Estate, Opp. Gollops Motors Showroom, SG Highway, Ahmedabad, Gujarat, India, <span style={{ whiteSpace: "nowrap" }}>382210</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUs