import React, { useState, useEffect } from 'react'; import './App.css';
import { BrowserRouter as Router, Routes, Route, useParams, unstable_HistoryRouter, Navigate, } from "react-router-dom";
import HomeLayout from './Layouts/HomeLayout';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import ContactUs from './Pages/ContactUs/ContactUs';
import Products from './Pages/Products/Products';
import ProductDetail from './Pages/DetailPage/ProductDetail';
import Series from './Pages/Series/Series';
import Collections from './Pages/Collections/Collections';


function App() {

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.addEventListener("load", () => {
      setLoading(false);
    });
  }, []);


  return (
    <>
      {/* {loading ? (
        <div className='preloader'>
          <div class="loader"></div>
        </div>
      ) : (
      )} */}
      <div>
        <Router>
          <Routes>
            <Route path='/' element={<HomeLayout Page={<Home />} />} />
            <Route path='/about' element={<HomeLayout Page={<About />} />} />
            <Route path='/privacypolicy' element={<HomeLayout Page={<PrivacyPolicy />} />} />
            <Route path='/product/:product_data' element={<HomeLayout Page={<ProductDetail />} />} />
            <Route path='/products' element={<HomeLayout Page={<Products />} />} />
            <Route path='/products/:category_id' element={<HomeLayout Page={<Products />} />} />
            <Route path='/series' element={<HomeLayout Page={<Series />} />} />
            <Route path='/collections' element={<HomeLayout Page={<Collections />} />} />
            <Route path='/contact' element={<HomeLayout Page={<ContactUs />} />} />
            <Route path='/privacy' element={<HomeLayout Page={<PrivacyPolicy />} />} />
          </Routes>
        </Router>
      </div>
    </>
  )
}

export default App