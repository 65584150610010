import React, { useEffect, useState } from 'react'
import { getCategoryList } from '../../API';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import DummyImg from '../../Images/dummy.jpg';

function Collections() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [collection, setCollection] = useState([]);
    useEffect(() => {
        axios.post(`${getCategoryList}?limit=1000`, {
            category_type: ['collection', 'series']
        })
            .then((response) => {
                const data = response.data.data.category.data.filter(category => category.category_type === 'collection')
                setCollection(data);
            })
    }, []);


    return (
        <div className='products-list'>
            <div className='container'>
                <div className='bannersection'>
                    <h2>Collections</h2>
                </div>
                <div className='series-section'>
                    <div className="row">
                        {
                            collection.length > 0 ? (
                                collection.map((val) => (
                                    <div className="col-md-3" key={val.id}>
                                        <NavLink className='product-card' to={`/products/${val.id}`}>
                                            <div className='product-img'>
                                                <img src={val.category_image ? val.category_image ? `https://thelaminc.com/admin/public/storage/${val.category_image}` : DummyImg : DummyImg} alt="" />
                                            </div>
                                            <div className="product-detail">
                                                <p>{val.category_name}</p>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))
                            ) : (
                                <div className='errormessage'>
                                    <h1 className='bounce'>404</h1>
                                    <h2>No data Found</h2>
                                    <NavLink to='/products'><button className='common-btn'>View All Products</button></NavLink>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Collections