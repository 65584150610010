import React from 'react'
import mainlogo from '../../Images/AT-White.png';
import './Footer.css';
import playStore from '../../Images/playStore.svg';
import appStore from '../../Images/appStore.svg';
import { NavLink } from 'react-router-dom';

import mapIcon from '../../Images/map.svg';
import phoneIcon from '../../Images/phone.svg';
import mailIcon from '../../Images/mail.svg';

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="footer-block">

            <div className="footer-logo">
              <h4>Available On</h4>
              <div className="store-link">
                <a href='https://play.google.com/store/apps/details?id=com.laminc' target='_blank' className="store">  <img src={playStore} alt="" /></a>
                <a href='https://apps.apple.com/in/app/the-mini/id6449925201' target='_blank' className="store"><img src={appStore} alt="" /></a>
              </div>
            </div>

            <div className="footer-links">
              <h4>Company</h4>
              <ul className="links">
                <li className="item">
                  <NavLink to="/series" className='nav-link'> Series </NavLink>
                </li>
                <li className="item">
                  <NavLink to="/collections" className='nav-link'> Collections </NavLink>
                </li>
                <li className="item">
                  <NavLink to="/products" className='nav-link'> Products </NavLink>
                </li>
                <li className="item">
                  <NavLink to="/about" className='nav-link'> About </NavLink>
                </li>
                <li className="item">
                  <NavLink to="/contact" className='nav-link'> Contact </NavLink>
                </li>
              </ul>
            </div>
            <div className="footer-contact">
              <h4>Help and Support</h4>
              <ul className="links">
                <li className="item">
                  <img src={mapIcon} alt="" />
                  <p>1, Sayona Estate, Opp. Gollops Motors Showroom, SG Highway, Ahmedabad, Gujarat, India, <span style={{ whiteSpace: "nowrap" }}>382210</span></p>
                </li>
                <li className="item">
                  <img src={phoneIcon} alt="" />
                  <a href="tel:+919825802008">+91 - 98258 02008</a>
                </li>
                <li className="item">
                  <img src={mailIcon} alt="" />
                  <div>
                    <a href="mailto:info@thelaminc.com">info@thelaminc.com</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyright">
            <p>© 2023 The Lam Inc. All Rights Reserved.</p>
            <a>Designed and Developed by <img src={mainlogo} width={'10px'} height={'14px'} alt="" /></a>
          </div>
        </div>

      </div>

    </>
  )
}

export default Footer