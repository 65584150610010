import React, { useEffect } from 'react'
import './About.css'

function About() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <section className='aboutus'>
                <div className="container">
                    <div className='bannersection'>
                        <h2>Company Profile</h2>
                    </div>
                    <div className="abouttext">
                        <p style={{ width: '85%' }}>
                            This is a range that is easy to select, yet hard to choose. Sifted and meticulously picked through an understanding of global trends and in consultation with a panel of domestic-market industry experts.
                        </p>
                        <p style={{ width: '65%' }}>
                            The Mini's Collection 1A is designed to delight you with a beautifully assembled range, interspersed with fun interesting stories. A light mix of all that can be used.
                        </p>
                        <p style={{ width: '65%' }}>
                            In short, it's not your typical laminate catalogue because it's simply not your typical range. Presenting a selected range of Laminated Decor Skins (LDS), conventionally known as Laminates.
                        </p>
                    </div>
                </div>
            </section>

        </>
    )
}

export default About