import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import axios from 'axios';
import slideone from '../../Images/Mini-Banner-2.png';
import slidetwo from '../../Images/Mini-Banner-1.png';
import slidethree from '../../Images/Mini-Banner-3.png';
import Slider from 'react-slick';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Rectangleone from '../../Images/Rectangle 2.png';
import knowmore from '../../Images/knowmore.svg';
import Rectangletwo from '../../Images/Rectangle 3.png';
import { NavLink } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getCategoryList } from '../../API';
import { AiOutlinePlus } from "react-icons/ai";
import DummyImg from '../../Images/dummy.jpg';

function Home() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    vertical: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowCircleLeftIcon onClick={() => sliderRef.current?.slickPrev()} />,
    nextArrow: <ArrowCircleRightIcon onClick={() => sliderRef.current?.slickNext()} />,
    afterChange: (current) => setCurrentSlide(current),
  };
  useEffect(() => {

    if (sliderRef.current) {
      sliderRef.current.slickGoTo(currentSlide);
    }
  }, [currentSlide]);
  // api
  const [categories, setCategories] = useState([]);
  useEffect(() => {

    axios.post(`${getCategoryList}?limit=1000`, {
      category_type: ['collection', 'series']
    })
      .then((response) => {
        setCategories(response.data.data.category.data);
      })
  }, []);

  const series = categories.filter(category => category.category_type === 'series').slice(0, 8);
  const collections = categories.filter(category => category.category_type === 'collection').slice(0, 8);


  // team slider
  const settingstwo = {
    dots: true,
    infinite: false,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* secfirst */}
      <section className='banner-section'>
        <div className="container">
          <div className="carousel-wrapper">
            <Slider {...settings} ref={sliderRef}>
              <div>
                <img src={slidetwo} alt="Slide 1" />
                <div className="slide-content">
                  <p className='heading'>Have nothing in your house that you do not know to be useful, or believe to be beautiful</p>
                  <p>- William Moris</p>
                </div>
              </div>
              <div>
                <img src={slideone} alt="Slide 2" />
                <div className="slide-content">
                  <p className='heading'>Many things difficult to design prove easy to performance.</p>
                  <p>- Samuel Johnson</p>
                </div>
              </div>
              <div>
                <img src={slidethree} alt="Slide 1" />
                <div className="slide-content">
                  <p className='heading'> For a house to be successful, the objects in it must communicate with one another, respond and balance one another.</p>
                  <p>- Andre Putman</p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        {/* <div className="static-arrow left-arrow" onClick={() => sliderRef.current?.slickPrev()}>
          <i><WestOutlinedIcon /></i>
        </div>
        <div className="static-arrow right-arrow" onClick={() => sliderRef.current?.slickNext()}>
          <i><EastOutlinedIcon /></i>
        </div> */}
      </section>
      <div className="container">
        <div className="mainborder"></div>
      </div>

      {/* about-sectiontion */}
      <section className='addsection section-gap'>
        <div className="container">
          <div className="about-section">
            <div className="imagecontainer">
              <img src={Rectangleone} alt="Image 1" />
              <img src={Rectangletwo} alt="Image 2" />
            </div>
            <div className="textcontainer">
              <h2>Welcome To THE LAM INC.</h2>
              <p>This is a range that is easy to select. yet hard to choose.Sifted and meticulously picked through an understanding of global trends and in consultation with a panel of domestic-market industry experts.</p>
              <NavLink to='/about' className='common-btn2'>Know More  <img src={knowmore} alt="" /></NavLink>
            </div>
          </div>
        </div>
      </section>

      {/* sec-two */}
      <section className='series-section section-gap'>
        <div className="container">
          <div className="title">
            <h2>Discover Best Series</h2>
            <NavLink to="/series" className='nav-link common-btn'>Explore More <AiOutlinePlus /> </NavLink>
          </div>
          <div className="row">
            {series.map((val) => (
              <div className="col-md-3" key={val.id}>
                <NavLink className='product-card' to={`/products/${val.id}`}>
                  <div className='product-img'>
                    <img src={val.category_image ? `https://thelaminc.com/admin/public/storage/${val.category_image}` : DummyImg} alt="" />
                  </div>
                  <div className="product-detail">
                    <p>{val.category_name}</p>
                  </div>
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* sec-three */}
      <section className='collection-section section-gap'>
        <div className="container">
          <div className="title">
            <h2>Discover Best Collection</h2>
            <NavLink to="/collections" className='nav-link common-btn'>Explore More <AiOutlinePlus /> </NavLink>
          </div>
          <div className="row">
            {collections.map((val) => (
              <div className="col-md-3" key={val.id}>
                <NavLink className='product-card' to={`/products/${val.id}`}>
                  <div className='product-img'>
                    <img src={val.category_image ? `https://thelaminc.com/admin/public/storage/${val.category_image}` : DummyImg} alt="" />
                  </div>
                  <div className="product-detail">
                    <p>{val.category_name}</p>
                  </div>
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      </section>


      {/* team-section */}
      {/* <section className='team-section section-gap'>
        <div className="container">
          <div className="title">
            <h2> Meet Our Team</h2>
          </div>
          <div className="row">
            <Slider {...settingstwo}>
              <div className="team-item">
                <img src={teamfour} alt="Team Member 1" className="team-image" />
                <div className="team-text">Brand Owner</div>
              </div>
              <div className="team-item">
                <img src={teamthree} alt="Team Member 2" className="team-image" />
                <div className="team-text">Branding Partner</div>
              </div>
              <div className="team-item">
                <img src={teamtwo} alt="Team Member 3" className="team-image" />
                <div className="team-text">Distribution Partner</div>
              </div>
              <div className="team-item">
                <img src={teamone} alt="Team Member 4" className="team-image" />
                <div className="team-text">Manufacturing Partner</div>
              </div>

            </Slider>
          </div>
        </div>
      </section> */}


    </>
  )
}

export default Home