import React, { useEffect } from 'react';
import './PrivacyPolicy.css'

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <section className='privacypolicy section-gap'>
        <div className="container">
          <div className='bannersection'>
            <h2>Privacy Policy  </h2>
          </div>
          <p>This Privacy Policy describes how The Mini collects, uses, and shares personal information when you use our mobile application The Mini (the "App") and the services provided through the App.</p>
          <h4>Information We Collect</h4>
          <p>Contact Information: When you submit a lead or contact form through the App, we collect personal information such as your name, email address, phone number, and any additional details you provide.</p>
          <h4>How We Use Your Information</h4>
          <p>Communication: We use the information collected to respond to your inquiries, provide customer support, and communicate with you about your requests or questions.</p>
          <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this Privacy Policy.</p>
          <p>We may also disclose your information when we believe it is appropriate to comply with the law, enforce our site policies, or protect our or others' rights, property, or safety.</p>
          <h4>Your Choices</h4>
          <p>You have the right to opt-out of receiving marketing communications from us by following the unsubscribe instructions provided in the communication.</p>
          <p>You can also contact us at <a href="tel:+917926890952">+91 79 - 2689 0952</a> | <a href="mailto:connect@thelaminc.com">connect@thelaminc.com</a>, <a href="mailto:info@thelaminc.com">info@thelaminc.com</a> to review, update, or delete the personal information we have collected about you.</p>
          <h4>Security</h4>
          <p>We implement a variety of security measures to maintain the safety of your personal information when you submit a lead or contact form.</p>
          <h4>Changes to This Privacy Policy</h4>
          <p>We may update this Privacy Policy from time to time. Any changes will be reflected on this page, and the revised policy will be effective when posted.</p>
          <h4>Contact Us</h4>
          <p>If you have any questions about this Privacy Policy, you can contact us at <a href="tel:+917926890952">+91 79 - 2689 0952</a> | <a href="mailto:connect@thelaminc.com">connect@thelaminc.com</a>, <a href="mailto:info@thelaminc.com">info@thelaminc.com</a>.</p>

        </div>
      </section>
    </>
  )
}

export default PrivacyPolicy