
import React from 'react'
import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'

function HomeLayout({Page}) {
  return (
   <>
   
   <Header/>
   {Page}
   <Footer/>
   </>
  )
}

export default HomeLayout